import type { Cart } from "~types/api/cart.types";

const pageview = (url: string, trackingId: string) => {
  if (!window.gtag || !window.gaEnabled) {
    return;
  }
  window.gtag("config", trackingId, {
    page_path: url,
  });
};

const event = ({ action, data }: { action: string; data: any }) => {
  if (!window.gtag || !window.gaEnabled) {
    return;
  }
  console.info(`GA Event sent (${action})`);
  window.gtag("event", action || "", data);
};

const addToCartEvent = ({
  newCart,
  addedAmountWithTax,
}: {
  newCart: Cart;
  addedAmountWithTax: number;
}) => {
  const gaCartItems = newCart.cartRows.map((cartRow) => {
    const product = cartRow.product;
    const productCategory = product.categories[0];
    return {
      item_id: product.reference || "",
      item_name: product.title || "",
      affiliation: "Power Connect",
      coupon: newCart.promoCode || "Non",
      discount: cartRow.discountAmount || 0,
      item_brand: product.brand?.title || "",
      item_category: productCategory?.title || "",
      price: cartRow.priceUnitWithoutTax || 0,
      quantity: cartRow.quantity || 0,
    };
  });

  const data = {
    currency: "EUR",
    value: addedAmountWithTax,
    items: gaCartItems,
  };

  gtag.event({ action: "add_to_cart", data });
};

const removeFromCartEvent = ({
  newCart,
  removedAmountWithTax,
}: {
  newCart: Cart;
  removedAmountWithTax: number;
}) => {
  const gaCartItems = newCart.cartRows.map((cartRow) => {
    const product = cartRow.product;
    const productCategory = product.categories[0];
    return {
      item_id: product.reference || "",
      item_name: product.title || "",
      affiliation: "Power Connect",
      coupon: newCart.promoCode || "Non",
      discount: cartRow.discountAmount || 0,
      item_brand: product.brand?.title || "",
      item_category: productCategory?.title || "",
      price: cartRow.priceUnitWithoutTax || 0,
      quantity: cartRow.quantity || 0,
    };
  });

  const data = {
    currency: "EUR",
    value: removedAmountWithTax,
    items: gaCartItems,
  };

  gtag.event({ action: "remove_from_cart", data });
};

export const gtag = { pageview, event, addToCartEvent, removeFromCartEvent };
